import React from 'react'
import PropTypes from 'prop-types'
import {Link} from 'gatsby'

const Footer = props => (
  <footer id="footer" style={props.timeout ? { display: 'none' } : {}}>
    <ul className="icons">
      <li>
        <a href="https://twitter.com/spherebox_jp" className="icon fa-twitter">
          <span className="label">Twitter</span>
        </a>
      </li>
      <li>
        <a
          href="https://www.facebook.com/Spherebox-881419438560049/"
          className="icon fa-facebook"
        >
          <span className="label">Facebook</span>
        </a>
      </li>
      <li>
        <a
          href="https://soundcloud.com/spherebox"
          className="icon fa-soundcloud"
        >
          <span className="label">Soundcloud</span>
        </a>
      </li>
      <li>
        <a
          href="https://www.youtube.com/channel/UCVZ7oNvXd9V8ELEbiiucJrQ"
          className="icon fa-youtube"
        >
          <span className="label">Youtube</span>
        </a>
      </li>
    </ul>
    <Link to="/" className="copyright"> Spherebox 2019</Link>
  </footer>
)

Footer.propTypes = {
  timeout: PropTypes.bool,
}

export default Footer
