import React, { Component } from 'react'
import Layout from '../components/layout'
import Footer from '../components/Footer'
import { graphql } from 'gatsby'
import { Link } from 'gatsby'

class ArchivePage extends Component {
  render(posts) {
    return (
      <Layout location={this.props.location}>
        <div className="posts-header">
          <h1>Archive</h1>
        </div>
        <div id="posts">
          <div className="posts">
            {this.props.data.allMarkdownRemark.edges.map(post => (
              <div className="post" key={post.node.id}>
                <div key={post.node.id}>
                  {post.node.frontmatter.date}
                  <Link to={post.node.frontmatter.path}>
                    <h3 className="news-title">
                      {post.node.frontmatter.title}
                    </h3>
                  </Link>
                </div>
              </div>
            ))}
          </div>
        </div>
        <Footer />
      </Layout>
    )
  }
}

export const pageQuery = graphql`
  query {
    allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }) {
      edges {
        node {
          id
          frontmatter {
            path
            title
            date
            author
          }
          excerpt(format: HTML)
          html
        }
      }
    }
  }
`

export default ArchivePage
